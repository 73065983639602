import { DecimalPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { apparitionBottomTop } from '../../animations/animations';
import { CtaActionDirective } from '../../directives/cta-action/cta-action.directive';
import { ImagesService } from '../../services/images-service/images.service';
import { PageScrollingService } from '../../services/page-scrolling/page-scrolling.service';
import { LiseretComponent } from '../liseret/liseret.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { AnimationPosition, IFormSTPCard, ISuperTopPage } from './super-top-page';
@Component({
  selector: 'nscf-super-top-page',
  templateUrl: 'super-top-page.component.html',
  styleUrls: ['super-top-page.component.scss'],
  animations: [apparitionBottomTop],
  standalone: true,
  imports: [NgClass, NgIf, TitleComponent, NgFor, CtaActionDirective, LiseretComponent, NgStyle, DecimalPipe, TrustpilotMicroStarComponent],
})
export class SuperTopPageComponent implements OnInit {
  @Input() data: ISuperTopPage;
  urlCover: any;
  @Input() bigViewPort: boolean;
  @Input() isBrowser: boolean;
  animState = AnimationPosition.BOTTOM;
  showBlockAnimation = false;
  displayScoring: boolean;

  constructor(private readonly pageScrollingService: PageScrollingService) {}

  ngOnInit(): void {
    this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
    if (this.bigViewPort && this.isBrowser) {
      setTimeout(() => {
        this.showBlockAnimation = true;
      }, 500);
    } else {
      this.showBlockAnimation = true;
    }
  }

  animStart(): void {
    if (this.bigViewPort) {
      this.animState = AnimationPosition.TOP;
    }
  }

  redirectForm(evt: any, card: IFormSTPCard): void {
    const target = evt.target;
    if (target.checked) {
      if (card.url) {
        setTimeout(() => {
          window.location.href = card.url.url;
        }, 500);
      }
    }
  }
}
