<div class="spacing-block stp__wrapper-container" [ngClass]="{ 'stp__wrapper-container--without': !data.mediaCover }">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nscf-title *ngIf="data.title" class="stp__title" [selector]="'h1'" [title]="data.title"></nscf-title>
        <p *ngIf="data.heading" class="stp__description">{{ data.heading }}</p>
        <nscf-trustpilot-micro-star class="stp__wrapper-avis" *ngIf="data.enableTrustPilot"></nscf-trustpilot-micro-star>
      </div>
    </div>
  </div>

  <div class="stp__card-wrapper-position-bg">
    <div class="container">
      <div *ngIf="showBlockAnimation" class="stp__card-container col-12 col-sm-8 col-md-6 col-lg-10 m-auto"
        style="position: relative" [@apparitionBottomTop]="{
          value: animState,
          params: { delay: '700ms', transformOrigin: 'bottom' }
        }" (@apparitionBottomTop.start)="animStart()">
        <p class="stp__card-title">{{ data.formTitle }}</p>
        <div class="stp__card-wrapper">
          <div class="stp__card-radio" *ngFor="let formCardInput of data.formCards; index as i">
            <input class="stp__card-input" (change)="redirectForm($event, formCardInput)" type="radio"
              [id]="'formCardInput-' + i" name="formCard" />
            <label class="stp__card-label" [for]="'formCardInput-' + i">
              <img [src]="formCardInput.picto?.url" width="36" height="36" alt="" />
              {{ formCardInput.title }}
            </label>
          </div>
        </div>

        <p *ngIf="data.formDescription" class="stp__card-description m-auto">
          {{ data.formDescription }}
        </p>
        <div appCtaAction [text]="data.ctaLabel" [ctaUrl]="data.ctaUrl" [replaceElements]="true"></div>
        <nscf-liseret></nscf-liseret>
      </div>
    </div>

    <div class="container-fluid stp__card-bg-image" *ngIf="data.mediaCover" [ngStyle]="urlCover" #cover></div>
  </div>
</div>